<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <template>
          <v-card elevation="0">
            <v-data-table
              :headers="TransitModelHeader"
              :items="allchalanArray"
              :search="search"
              :loading="loading"
              loading-text="Please wait..."
              item-key="id"
              class="elevation-0"
              style="background: #f5f5f5"
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    All Flash Sale Products
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pa-5">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.startdate="{ item }">
                <p class="mb-0">
                  {{ new Date(item.start_date).toLocaleString() }}
                </p>
              </template>
              <template v-slot:item.enddate="{ item }">
                <p class="mb-0">
                  {{ new Date(item.end_date).toLocaleString() }}
                </p>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <!-- <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>


<script>
export default {
  data: () => ({
    loading: true,
    search:"",

    TransitModelHeader: [
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "spec_data.color", sortable: false },
      { text: "Size", value: "spec_data.size", sortable: false },
      { text: "quantity", value: "spec_data.quantity", sortable: false },

      { text: "Start Time", value: "startdate" },
      { text: "End Time", value: "enddate", sortable: false },
      // { text: "Selling Price", value: "comments", sortable: false },
    ],

    allchalanArray: [],
  }),

  methods: {
    TransferSelectInfo() {
      // console.log(this.trnasferob);
      axios.get("product/get_all_flash_product/").then((response) => {
        if (response.data.success) {
          this.allchalanArray = response.data.data;
          this.loading = false;
        }
      });
    },
  },

  mounted() {
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

